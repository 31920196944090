import { Navigate, Outlet, Route, Routes } from 'react-router';
import { AppLayout } from '../layout/AppLayout.jsx';
import { IndividualPage } from './individual/IndividualPage.jsx';
import { GeneralPage } from './general/GeneralPage.jsx';
import { UploadPage } from './upload/UploadPage.jsx';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/individual" />} />
            <Route
                element={
                    <AppLayout>
                        <Outlet />
                    </AppLayout>
                }
            >
                <Route path="/individual" element={<IndividualPage />} />
                <Route path="/general" element={<GeneralPage />} />
                <Route path="/upload" element={<UploadPage />} />
            </Route>
        </Routes>
    );
};
