import { BrowserRouter } from 'react-router-dom/dist/index.js';
import './assets/styles/index.scss';
import { AppRoutes } from './pages/index.jsx';
import { ConfigProvider } from 'antd';
import 'dayjs/locale/ja';
import locale from 'antd/locale/ja_JP';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const queryClient = new QueryClient();

function App() {
    return (
        <ConfigProvider locale={locale} theme={{ hashed: false }}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <AppRoutes />
                </BrowserRouter>
            </QueryClientProvider>
        </ConfigProvider>
    );
}

export default App;
