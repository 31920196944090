import { Layout } from 'antd';
import { AppSidebar } from './AppSidebar.jsx';

export const AppLayout = ({ children }) => {
    return (
        <div className="app-layout flex min-h-[100vh]">
            <AppSidebar />
            <Layout.Content>{children}</Layout.Content>
        </div>
    );
};
