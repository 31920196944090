import { api } from '../utils/api.util.js';

export const getListUsers = async () => {
    const { data } = await api.get('/get_all_user');
    return data;
};

export const getStatisticUser = async (form) => {
    const { data } = await api.post('/statistic_user', form);
    return data;
};

export const getStatisticAll = async (form) => {
    const { data: { result } } = await api.post('/statistic_all', form);
    if (result) {
        return {
            general: {
                general_health: result.general_statistic.general_health,
                general_sentiment: result.general_statistic.general_sentiment,
                physical: result.general_statistic.score_physical,
                mental: result.general_statistic.score_mental,
            },
            statistic_month: result.statistic_month,
            statistic_weekday: result.statistic_weekday,
            sentiment: result.sentiment,
            calendar: result.calendar,
            matrix: result.matrix,
            percentage: result.percentage,
            user_best: result.top_user_best_score,
            user_lowest: result.top_user_lowest_score,
            event_check_out: result.event_check_out,
            event_check_in: result.event_check_in,
            line_chart: result.line_chart,
            matrix_general: result.matrix_general,
            matrix_mental: result.matrix_mental,
            matrix_physical: result.matrix_physical,
        };
    }
    return {
        general: {
            general_health: 0,
            general_sentiment: 0,
            physical: 0,
            mental: 0,
        },
        statistic_month: [],
        statistic_weekday: [],
        sentiment: [],
        calendar: [],
        matrix: [],
        percentage: [],
        user_best: [],
        user_lowest: [],
        event_check_in: [],
        event_check_out: [],
        line_chart: [],
        matrix_general: [],
        matrix_mental: [],
        matrix_physical: []
    };
};

export const uploadFileData = async (formData) => {
    const { data } = await api.post('/upload', formData, {
        headers: { 'Content-Type': `multipart/form-data; boundary=${formData._boundary}` },
    });
    return data;
};

export const exportData = async (format) => {
    return await api.get('/export_data', {
        params: {
            output_format: format
        },
        headers: { 'Accept': 'application/json' },
        responseType: 'blob'
    });
};
