import { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload, Row, Col, Space, Select, Divider, Spin } from 'antd';
import { uploadFileData, exportData } from '../../requests/user.request';
import { DownloadOutlined } from '@ant-design/icons';

export const UploadPage = () => {
    const [fileList, setFileList] = useState([]);
    const [type, setType] = useState("xlsx");
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('file', fileList[0]);
        setUploading(true);
        uploadFileData(formData)
            .then(() => {
                setFileList([]);
                message.success('アップロードに成功しました。');
            })
            .catch(() => {
                message.error('エラーが発生しました');
            })
            .finally(() => {
                setUploading(false);
            });
    };

    const handleExport = () => {
        setLoading(true);
        exportData(type).then((res) => {
            let filename = `file.${type}`;
            filename = decodeURI(filename);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
        }).catch((err) => {
            console.log(err);
            message.error("エラーが発生しました")
        }).finally(() => {
            setLoading(false);
        })
    }

    const props = {
        onRemove: () => {
            setFileList([]);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList,
        accept: '.xlsx, .csv',
        maxCount: 1,
    };

    return (
        <div className="upload">
            <Row align={'middle'}>
                <Col span={4}>エクスポートデータ</Col>
                <Col span={12}>
                    <Space>
                        <Select
                            className="w-40"
                            defaultValue={type}
                            options={[
                                {
                                    value: 'xlsx',
                                    label: 'xlsx',
                                },
                                {
                                    value: 'csv',
                                    label: 'csv',
                                },
                            ]}
                            onChange={(e) => setType(e)}
                        />
                        <Button icon={loading ? <Spin size="small"/> : <DownloadOutlined />} disabled={loading} onClick={handleExport}>
                            エクスポート
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={4} className='mt-1'>アップロードデータ</Col>
                <Col span={12}>
                    <Space direction="vertical">
                        <Upload {...props}>
                            <Button className="w-40" icon={<UploadOutlined />}>
                                ファイルを選択
                            </Button>
                        </Upload>
                        <Button className="w-40" onClick={handleUpload} disabled={fileList.length === 0} loading={uploading}>
                            {uploading ? 'アップロード中' : 'アップロード開始'}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </div>
    );
};
