export const COLOR = {
    mental: '#CC4B14',
    physical: '#095446',
    sentiment: '#CC144F',
    matrix: ['#174c83', '#0d5fbb', '#7eadfc', '#fd8b6f', '#aa3523'],
    good: '#1FBA00',
    neutral: '#008eff',
    bad: '#FA7070',
    none: '#454545',
}

export const color = {
    GOOD: `text-[#1FBA00]`,
    NEUTRAL: `text-[#008eff]`,
    BAD: `text-[#FA7070]`,
    NONE: `text-[#454545]`,
};
