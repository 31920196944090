import { useQuery } from '@tanstack/react-query';
import { getStatisticAll } from '../requests/user.request';

const KEY = 'general';

export const useGeneralData = (datepicker, department) => {
    return useQuery({
        queryKey: [KEY, datepicker, department],
        refetchOnWindowFocus: false,
        queryFn: async () => await getStatisticAll(JSON.stringify(Object.assign(datepicker, { department }))),
    });
};
