import { Button, Layout, Menu } from 'antd';
import { FaBuilding, FaChevronLeft, FaChevronRight, FaUser, FaUpload } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const menuItems = [
    {
        key: '/individual',
        label: '個人',
        icon: <FaUser />,
    },
    {
        key: '/general',
        label: '全社',
        icon: <FaBuilding />,
    },
    {
        key: '/upload',
        label: 'インポート/エクスポート',
        icon: <FaUpload />,
    },
];

export const AppSidebar = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState('');
    const [openKey, setOpenKey] = useState(['']);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        for (const item of menuItems) {
            if (location.pathname.includes(item?.key)) {
                setSelectedMenu(item?.key);
                setOpenKey([item?.key]);
            }

            const children = item?.children;
            if (children) {
                for (const child of children) {
                    if (location.pathname.includes(child?.key)) {
                        setSelectedMenu(child?.key);
                    }
                }
            }
        }
    }, [location.pathname]);

    const handleMenuSelect = ({ key }) => {
        navigate(key);
    };

    return (
        <Layout.Sider
            className="min-h-full"
            collapsible
            collapsed={collapsed}
            collapsedWidth={60}
            width={240}
            trigger={<></>}
        >
            <Menu
                items={menuItems}
                mode="inline"
                onSelect={handleMenuSelect}
                selectedKeys={[selectedMenu]}
                openKeys={openKey}
                onOpenChange={setOpenKey}
            />
            <Button className="trigger" onClick={() => setCollapsed(!collapsed)}>
                {collapsed ? (
                    <FaChevronRight />
                ) : (
                    <>
                        <FaChevronLeft />
                        {/* <span> 崩壊</span> */}
                    </>
                )}
            </Button>
        </Layout.Sider>
    );
};
