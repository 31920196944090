import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFaceDizzy,
    faFaceLaugh,
    faFaceSmile,
    faHeart,
    faHeartBroken,
    faHeartPulse,
    faBrain,
    faDumbbell,
} from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { color } from '../constants/color.constant.js';

export const GeneralHealth = ({ values }) => {
    const { physical, mental, general_health, general_sentiment } = values;

    const physicalDisplay = useMemo(() => {
        if (!physical) {
            return {
                color: color.NONE,
                icon: faDumbbell,
            };
        } else if (physical > 80) {
            return {
                color: color.GOOD,
                icon: faDumbbell,
            };
        } else if (physical > 60) {
            return {
                color: color.NEUTRAL,
                icon: faDumbbell,
            };
        } else {
            return {
                color: color.BAD,
                icon: faDumbbell,
            };
        }
    }, [physical]);

    const mentalDisplay = useMemo(() => {
        if (!mental) {
            return {
                color: color.NONE,
                icon: faBrain,
            };
        } else if (mental > 80) {
            return {
                color: color.GOOD,
                icon: faBrain,
            };
        } else if (mental > 60) {
            return {
                color: color.NEUTRAL,
                icon: faBrain,
            };
        } else {
            return {
                color: color.BAD,
                icon: faBrain,
            };
        }
    }, [mental]);

    const healthDisplay = useMemo(() => {
        if (!general_health) {
            return {
                color: color.NONE,
                icon: faHeart,
            };
        } else if (general_health > 80) {
            return {
                color: color.GOOD,
                icon: faHeartPulse,
            };
        } else if (general_health > 60) {
            return {
                color: color.NEUTRAL,
                icon: faHeart,
            };
        } else {
            return {
                color: color.BAD,
                icon: faHeartBroken,
            };
        }
    }, [general_health]);

    const sentimentDisplay = useMemo(() => {
        if (!general_sentiment) {
            return {
                color: color.NONE,
                icon: faFaceSmile,
            };
        } else if (general_sentiment > 80) {
            return {
                color: color.GOOD,
                icon: faFaceLaugh,
            };
        } else if (general_sentiment > 60) {
            return {
                color: color.NEUTRAL,
                icon: faFaceSmile,
            };
        } else {
            return {
                color: color.BAD,
                icon: faFaceDizzy,
            };
        }
    }, [general_sentiment]);

    return (
        <div className="general h-full flex items-center justify-around">
            <div className={`mental text-center ${healthDisplay.color}`}>
                <FontAwesomeIcon icon={healthDisplay.icon} size="6x" />
                <div className='mt-1'>{general_health}/100</div>
                <div className='mt-1'> 健康（総合）</div>
            </div>
            <div className={`mental text-center ${physicalDisplay.color}`}>
                <FontAwesomeIcon icon={faDumbbell} size="6x" />
                <div className='mt-1'>{physical}/100</div>
                <div className='mt-1'> 身体的健康</div>
            </div>
            <div className={`mental text-center ${mentalDisplay.color}`}>
                <FontAwesomeIcon icon={faBrain} size="6x" />
                <div className='mt-1'>{mental}/100</div>
                <div className='mt-1'> 精神的健康</div>
            </div>
            <div className={`mental text-center ${sentimentDisplay.color}`}>
                <FontAwesomeIcon icon={sentimentDisplay.icon} size="6x" />
                <div className='mt-1'>{general_sentiment}/100</div>
                <div className='mt-1'> 感情</div>
            </div>
        </div>
    );
};
