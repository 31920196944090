import { DatePicker, Space, Spin, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useGeneralData } from '../../hooks/useGeneralData.js';
import { Column, Line } from '@ant-design/charts';
import { Heatmap, Pie } from '@ant-design/plots';
import { GeneralHealth } from '../../components/GeneralHealth.jsx';
import { GeneralCalendar } from '../../components/GeneralCalendar.jsx';
import { COLOR } from '../../constants/color.constant.js';
import * as dayjs from 'dayjs';
import CustomSelect from '../../components/Customize/CustomSelect.jsx';
const { RangePicker } = DatePicker;

export const getDate = (type) => {
    const date = new Date();
    if (type === 'start') {
        date.setDate(date.getDate() - 30);
    }
    const currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return currentDate;
};

export const LoadingBox = () => {
    return (
        <div className="flex h-full">
            <div className="m-auto">
                <Spin />
            </div>
        </div>
    );
};

const DEPARTMENT = [
    '全部署',
    '製造',
    '生産技術',
    '品質保証',
    '素形材営業',
    '環境設備',
    'エンジニアリング営業',
    '鋼構造技術',
    '生産統括',
    '人事総務',
    '素形材開発技術',
    '企画管理',
    '取締役',
    '建築技術',
    '経理',
    '安全衛生',
    '監査役',
    '福山製造所',
].map((each) => ({ value: each, label: each }));

export const GeneralPage = () => {
    const [datepicker, setDatePicker] = useState({
        start: getDate('start'),
        end: getDate('end'),
    });
    const [department, setDepartment] = useState(DEPARTMENT[0].value);
    const [statistic, setStatistic] = useState(0);
    const { data, isLoading } = useGeneralData(datepicker, department);

    const handleChangeStatistics = (e) => {
        setStatistic(e);
    };

    const handleChangeDepartment = (value) => {
        setDepartment(value);
    };

    return (
        <div className="individual">
            <div className="filter">
                <Space direction="horizontal">
                    <CustomSelect defaultValue={department} options={DEPARTMENT} onChange={handleChangeDepartment} />
                    <RangePicker
                        defaultValue={[dayjs(getDate('start')), dayjs(getDate('end'))]}
                        allowClear={true}
                        onChange={(d, s) => setDatePicker({ start: s[0], end: s[1] })}
                    />
                </Space>
            </div>
            <div className="statistics mt-6 grid grid-col-1 xl:grid-cols-6 gap-8">
                <div className="card xl:col-span-3">
                    {isLoading ? (
                        <LoadingBox />
                    ) : (
                        <Space direction="vertical" size={140} className="h-full w-full">
                            <p className="text-center">総合的な評価</p>
                            <GeneralHealth values={data.general} />{' '}
                        </Space>
                    )}
                </div>
                <div className="card xl:col-span-3">
                    {isLoading ? (
                        <LoadingBox />
                    ) : (
                        <GeneralCalendar start={datepicker.start} end={datepicker.end} calendar={data.calendar} />
                    )}
                </div>
                <div className="card xl:col-span-2">
                    <div className="h-full">
                        {isLoading ? (
                            <LoadingBox />
                        ) : (
                            <div>
                                <p className="text-center pb-4">健康状態の割合</p>
                                <Pie
                                    data={data.percentage}
                                    angleField="value"
                                    colorField="label"
                                    appendPadding={10}
                                    legend={{ position: 'top-right' }}
                                    color={[COLOR.good, COLOR.neutral, COLOR.bad]}
                                    animation={false}
                                    label={{
                                        type: 'inner',
                                        content: ({ percent }) =>
                                            percent * 100 > 5 ? `${(percent * 100).toFixed(0)}%` : '',
                                    }}
                                ></Pie>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card xl:col-span-2">
                    <div className="h-full">
                        {isLoading ? (
                            <LoadingBox />
                        ) : (
                            <div>
                                <p className="text-center pb-4">
                                    {statistic === 0 ? '曜日別の健康指数' : '月別の平均健康指数'}
                                </p>
                                <div className="flex justify-end">
                                    <Select
                                        className="w-20"
                                        defaultValue={statistic}
                                        options={[
                                            {
                                                value: 0,
                                                label: '週間',
                                            },
                                            {
                                                value: 1,
                                                label: '月間',
                                            },
                                        ]}
                                        onChange={handleChangeStatistics}
                                    />
                                </div>
                                {statistic === 0 ? (
                                    <Column
                                        data={data.statistic_weekday}
                                        isGroup
                                        xField="day"
                                        yField="value"
                                        seriesField="type"
                                        color={[COLOR.physical, COLOR.mental]}
                                    ></Column>
                                ) : (
                                    <Column
                                        data={data.statistic_month}
                                        isGroup
                                        xField="month"
                                        yField="value"
                                        seriesField="type"
                                        color={[COLOR.physical, COLOR.mental]}
                                    ></Column>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="card xl:col-span-2">
                    <div className="h-full">
                        <div className="h-full">
                            {isLoading ? (
                                <LoadingBox />
                            ) : (
                                <div>
                                    <p className="text-center pb-4">感情分析</p>
                                    <Column
                                        data={data.sentiment}
                                        xField="sentiment"
                                        yField="指数"
                                        legend={{ position: 'top-right' }}
                                        color={COLOR.sentiment}
                                        xAxis={{
                                            label: {
                                                style: {
                                                    fontSize: 8,
                                                },
                                            },
                                        }}
                                    ></Column>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="card xl:col-span-3">
                    <div className="h-full">
                        {isLoading ? (
                            <LoadingBox />
                        ) : (
                            <div>
                                <p className="text-center pb-4">合計点数が高い人TOP5</p>
                                <Column
                                    data={data.user_best}
                                    isGroup
                                    xField="user_name"
                                    yField="value"
                                    seriesField="type"
                                    color={[COLOR.physical, COLOR.mental]}
                                    xAxis={{
                                        label: {
                                            style: {
                                                fontSize: 8,
                                            },
                                        },
                                    }}
                                ></Column>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card xl:col-span-3">
                    <div className="h-full">
                        {isLoading ? (
                            <LoadingBox />
                        ) : (
                            <div>
                                <p className="text-center pb-4">合計点数が低い人TOP5</p>
                                <Column
                                    data={data.user_lowest}
                                    isGroup
                                    xField="user_name"
                                    yField="value"
                                    seriesField="type"
                                    color={[COLOR.physical, COLOR.mental]}
                                    xAxis={{
                                        label: {
                                            style: {
                                                fontSize: 8,
                                            },
                                        },
                                    }}
                                ></Column>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card xl:col-span-3">
                    <div className="h-full">
                        {isLoading ? (
                            <LoadingBox />
                        ) : (
                            <div>
                                <p className="text-center pb-4">日ごとの健康状態</p>
                                <LineMultipleComponent data={data.line_chart} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="card xl:col-span-3">
                    <div className="h-full">
                        {isLoading ? (
                            <LoadingBox />
                        ) : (
                            <div>
                                <p className="text-center pb-4">体温と感情の相関関係</p>
                                <Heatmap
                                    data={data.matrix}
                                    xField="temperature"
                                    yField="sentiment"
                                    colorField="指数"
                                    color={COLOR.matrix}
                                    legend={{ position: 'top-right' }}
                                    label={{ color: '#fff' }}
                                    tooltip={{ showMarkers: false }}
                                ></Heatmap>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card xl:col-span-2">
                    <div className="h-full">
                        <div className="h-full">
                            {isLoading ? (
                                <LoadingBox />
                            ) : (
                                <div>
                                    <p className="text-center pb-4">体温と健康（総合）の相関関係</p>
                                    <Heatmap
                                        data={data.matrix_general}
                                        xField="temperature"
                                        yField="score_health"
                                        colorField="指数"
                                        color={COLOR.matrix}
                                        legend={{ position: 'top-right' }}
                                        label={{ color: '#fff' }}
                                        tooltip={{ showMarkers: false }}
                                    ></Heatmap>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="card xl:col-span-2">
                    <div className="h-full">
                        <div className="h-full">
                            {isLoading ? (
                                <LoadingBox />
                            ) : (
                                <div>
                                    <p className="text-center pb-4">体温と精神的健康の相関関係</p>
                                    <Heatmap
                                        data={data.matrix_mental}
                                        xField="temperature"
                                        yField="score_health"
                                        colorField="指数"
                                        color={COLOR.matrix}
                                        legend={{ position: 'top-right' }}
                                        label={{ color: '#fff' }}
                                        tooltip={{ showMarkers: false }}
                                    ></Heatmap>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="card xl:col-span-2">
                    <div className="h-full">
                        <div className="h-full">
                            {isLoading ? (
                                <LoadingBox />
                            ) : (
                                <div>
                                    <p className="text-center pb-4">体温と身体的健康の相関関係</p>
                                    <Heatmap
                                        data={data.matrix_physical}
                                        xField="temperature"
                                        yField="score_health"
                                        colorField="指数"
                                        color={COLOR.matrix}
                                        legend={{ position: 'top-right' }}
                                        label={{ color: '#fff' }}
                                        tooltip={{ showMarkers: false }}
                                    ></Heatmap>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="card xl:col-span-3">
                    <div className="h-full">
                        {isLoading ? (
                            <LoadingBox />
                        ) : (
                            <div>
                                <p className="text-center pb-4">出勤時の出来事と健康（総合）の相関関係</p>
                                <Heatmap
                                    data={data.event_check_in}
                                    xField="range_score"
                                    yField="event"
                                    colorField="指数"
                                    color={COLOR.matrix}
                                    legend={{ position: 'top-right' }}
                                    label={{ color: '#fff' }}
                                    tooltip={{ showMarkers: false }}
                                ></Heatmap>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card xl:col-span-3">
                    <div className="h-full">
                        {isLoading ? (
                            <LoadingBox />
                        ) : (
                            <div>
                                <p className="text-center pb-4">退勤時の出来事と健康（総合）の相関関係</p>
                                <Heatmap
                                    data={data.event_check_out}
                                    xField="range_score"
                                    yField="event"
                                    colorField="指数"
                                    color={COLOR.matrix}
                                    legend={{ position: 'top-right' }}
                                    label={{ color: '#fff' }}
                                    tooltip={{ showMarkers: false }}
                                ></Heatmap>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const LineMultipleComponent = ({ data }) => {
    const [option, setOption] = useState('day');
    const [lineData, setLineData] = useState(data[option] || []);

    useEffect(() => {
        setLineData(data[option] || []);
    }, [data]);

    const handleChange = (val) => {
        setOption(val);
        setLineData(data[val] || []);
    };

    return (
        <div>
            <div className="flex justify-end">
                <Select
                    className="w-15"
                    defaultValue={option}
                    options={[
                        {
                            value: 'day',
                            label: '日',
                        },
                        {
                            value: 'month',
                            label: '月',
                        },
                        {
                            value: 'year',
                            label: '年',
                        },
                    ]}
                    onChange={handleChange}
                />
            </div>
            <Line
                data={lineData}
                padding="auto"
                xField="time"
                yField="score"
                seriesField="type"
                animation={{
                    appear: {
                        animation: 'path-in',
                        duration: 2000,
                    },
                }}
                lineStyle={{
                    lineWidth: 2
                }}
                color={[COLOR.physical, COLOR.mental, COLOR.sentiment]}
            />
        </div>
    );
};
